/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu css-1aeficv --style4 pb--30 pl--40 pr--40 pt--30" menu={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17430/17c7bd4bca804592911eeee4ea478674_e=19x256x1895x358_bri=64_con=104_sat=106__s=3000x_.jpg);
    }
  
    `}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Subtitle className="subtitle-box subtitle-box--invert" content={"... jen jednou si tak líznout"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr5 btn-box--sbtn2 ff--2 fs--30 w--400" innerClassName="pl--0 pr--0" use={"page"} href={"/svatba"} style={{"backgroundColor":"rgba(5,119,75,1)"}} target={""} content={"<span style=\"font-weight: bold;\">SVATBA&nbsp;</span>"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-15io8ka bg--top --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17430/7d6a4f48cff04d97ae85da4c30555d27_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--102 w--300 title-box--invert lh--12" content={"<br>VOSÍ MED<br>delikatesa pro znalce<br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--cColor2 w--900 mt--50" href={"#uvod-2"} style={{"maxWidth":213}} content={"<span style=\"font-weight: bold;\">BZZZZZ...</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1270}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"Kouzlo jednoho procenta"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":860}} content={"Zatímco včely chráníme a inspirujeme se jejich pracovitostí, vosy jsou pro nás většinou jen obtížný hmyz. Přitom i vosa je užitečná. Stejně jako včela pracuje jako opilovač květů, to znamená, že nasává a nasává... nektar samozřejmě, a mimoděk nám tak zajišťuje úrodu.&nbsp; Vosa nás také zbavuje některých škůdců, zejména much. Ano, vosa má žihadlo, jehož bodnutí pekelně bolí, ale to včela koneckonců taky. Věděli jste ale, že žihadlo má jen&nbsp; jedno procento ze všech vosích druhů, které na světě žijí?&nbsp;<br>Svým způsobem je nám vosa možná podobnější než včela. Spolehlivá oddaná dělnice schopná i zemřít pro své společenství, nebo bzučící a těžko odehnatelný společník, který přiletí právě ve chvíli, kdy zavoní gril a otevřete si první pivko? Které podobenství je člověku blíž?<br>&nbsp;<br>A pak je tu ten med, o němž se dosud málo ví. A to je právě kouzlo jednoho procenta. V porovnání se světovou produkcí medu je toho vosího k mání sotva setina - tedy znovu právě jedno procento. A dávají ho právě a jen ty bodavý vosy. Jenže vosí žihadlo dostal za život aspoň jednou každý z nás, ale kolik z vás si lízlo vosího medu, aha!? Takže je jasný, že ty procenta nejsou stejně velký. Přitom jedno procento by prostě mělo být 1%, ne?<br>Naši předkové to takhle asi ani nezkoumali, prostě měli od svých předků v instinktech uložené to, co zas oni měli od svých předků, totiž že kdo se dostane k lizu, má se ho držet. Na procenta bude vždycky čas.&nbsp; &nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--10" name={"informace"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s5 --center el--2 pl--0 pr--0 flex--center" anim={"4"} animS={"5"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/2ff5b1f193904d4296737367dde054e6_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17430/2ff5b1f193904d4296737367dde054e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/2ff5b1f193904d4296737367dde054e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/2ff5b1f193904d4296737367dde054e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/2ff5b1f193904d4296737367dde054e6_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Už jsi jed vosí med?&nbsp;"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/17430/e7c3786f4b6a4ee2bf355f5382773108_s=660x_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/17430/e7c3786f4b6a4ee2bf355f5382773108_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/e7c3786f4b6a4ee2bf355f5382773108_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/e7c3786f4b6a4ee2bf355f5382773108_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Správně by asi mělo být \"Už jsi jedl vosí medl\" a na světě existuje nejméně jeden člověk, který to takto pravděpodobně opraví... a tomu také patří přání všeho nejlepšího!&nbsp; To ale nic nemění na tom, že vosí med je delikatesa, \"co svět neviděl\". <br><br>Shrňme si jeho přednosti:<br>A) Stopa stopových prvků<br>B) Významné množství užitečných vitaminů L, E, D, R, které se vážou na skupinu OH¨¨. Skvělá stáčená vosovinka :)<br>C) Můžete skladovat v instantní podobě. Ředí se vodou, vodkou, vodovkama.&nbsp;<br>D) Máte vždy u sebe, zásadně jako potravinový balíček.<br>E) Nikdo vám ho neukradne, nikoho ani nenapadne, že vosí med máte.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--50" name={"informace-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s5 --center el--2 pb--50 pl--0 pr--0 flex--center" anim={"5"} animS={"5"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Prolízat se do dna&nbsp;"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/17430/867e36faac674d13bd003389e97c2dc1_s=660x_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/17430/867e36faac674d13bd003389e97c2dc1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/867e36faac674d13bd003389e97c2dc1_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box" content={"Někdo dopíjí do dna sklenice kořalky, my se prolížeme sklenicí medu. Samozřejmě vosího. Zkrátka, řekneme si něco o jeho historii. <br>Vosí med nepochází z Transylvánie, jak se na internetu mylně píše, ale ani z vos.&nbsp; Je důsledkem jejich popíjení. Z toho je vidět, že popíjení není nic neužitečného.&nbsp;<br>Na otázku, zda byla dřív slepice nebo vejce, odpověď neznáme, bezpečně ale víme, že nejdříve byla vosa a až potom med. Do Evropy se dostal společně s hmyzem, do sklenic díky vosařům. Jestli chcete najít med,&nbsp; najděte vosaře.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/234790ed90b9433e8ad8606725bf9b33_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17430/234790ed90b9433e8ad8606725bf9b33_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/234790ed90b9433e8ad8606725bf9b33_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/234790ed90b9433e8ad8606725bf9b33_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/234790ed90b9433e8ad8606725bf9b33_s=1400x_.jpg 1400w"} position={{"x":"-89.19887955182072%","y":"-0.42016806722689076%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-v1h5su --full pb--60 pt--60" name={"paticka"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17430/71572bdce8684b4da7e94f38fc758e0c_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s5 --center el--1 flex--center" anim={"6"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86 title-box--invert" content={"HEZKÉ NAROZENINY!"}>
              </Title>

              <Button className="btn-box" href={"/potvrdit-ucast"} content={"CHCI SE STÁT VOSAŘEM"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}